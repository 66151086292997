<template>
  <div class="users-filter">
    <div class="users-filter__label">
      {{ textForFilterByRouter }}
    </div>
    <input 
      v-model="filterValue"
      class="user-filter__input"
      type="text" 
      @input="debouncedEmit" 
    >
  </div>
</template>
<script>

export default {
  props: {
    clearValue: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      timer: null,
      filterValue: '',
    }
  },
  computed: {
    textForFilterByRouter() {
      const routeName = this.$route.name

      return routeName === 'admin-panel-promo' ? 'Filter promo by name:' : 'Filter user by email:'
    }
  },
  watch: {
    clearValue() {
      this.filterValue = ''
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer)
  },
  methods: {
    debouncedEmit() {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.$emit('inputChange', this.filterValue)
      }, 800)
    }
  },
}
</script>

<style scoped>
.users-filter {
  display: flex;
  align-items: center;
  gap: 16px;
}
.users-filter__label {
  white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
}
.user-filter__input {
  width: 100%;
  height: 40px;
  padding: 0 16px;
  border: 1px solid var(--reflex-bluet);
  border-radius: 4px;
  font-family: inherit;
  font-size: 16px;
}
@media (max-width: 767px) {
  .users-filter {
    align-items: start;
    flex-direction: column;
    gap: 8px;
  }
}
</style>