<template>
  <div class="admin-panel__wrapper">
    <div class="admin-panel">
      <div class="admin-panel__head-wrapper">
        <div class="admin-panel__head">
          <div class="admin-panel__head-top">
            <div class="bold-600 fs-36">
              Admin panel
            </div>
            <AdminNavigation />
          </div>
          <div class="admin-panel__filter">
            <div class="admin-panel__filter-left">
              <UsersFilter
                :clear-value="adminFilterClearTrigger"
                @inputChange="filterInputChange"
              />
            </div>
            <div
              v-if="isPromo"
              class="admin-panel__filter-right"
            >
              <gl-button
                dark
                full
                style="height: 40px"
                title="Create New Promo"
                @click="createNewPromoModalOpen"
              />
            </div>
          </div>
        </div>
      </div>
      <router-view />
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import AdminNavigation from './components/AdminNavigation.vue'
import UsersFilter from './components/UsersFilter.vue'
import GlButton from '@/components/gl-button'

export default {
  name: 'AdminPanel',
  components: {
    AdminNavigation,
    UsersFilter,
    GlButton,
  },
  computed: {
    ...mapGetters('adminPanel', ['adminFilterClearTrigger']),
    ...mapGetters('user', ['isAdmin']),
    isPromo() {
      return this.$route.name === 'admin-panel-promo'
    }
  },
  methods: {
    ...mapMutations('adminPanel', ['SET_FILTER_VALUE', 'SWITCH_CREATE_PROMO_MODAL_TRIGGER']),
    filterInputChange(inputValue) {
      this.SET_FILTER_VALUE(inputValue)
    },
    createNewPromoModalOpen() {
      this.SWITCH_CREATE_PROMO_MODAL_TRIGGER(null)
    },
  },
}
</script>

<style>
.admin-panel__wrapper {
  display: flex;
  justify-content: center;
  background-color: var(--light-grey-f-8);
  padding-top: var(--header-height);
  font-family: 'Montserrat', sans-serif;
  overflow: hidden;
}
.admin-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.admin-panel__head-wrapper {
  display: flex;
  justify-content: center;
}
.admin-panel__head {
  width: var(--main-content-width);
  max-width: var(--main-content-max-width);
  padding-top: 24px;
  padding-bottom: 24px;
}
.admin-panel__head-top {
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 24px;
}

.admin-panel__filter {
  display: flex;
  align-items: center;
  gap: 16px;
}
.admin-panel__filter-left {
  flex: 1 0 auto;
}
.admin-panel__filter-right {
  flex: 0 0 233px;
}
.admin-panel__filter-left + .admin-panel__filter-right {
  padding-left: 16px;
  border-left: 1px solid var(--dark-grey-d-3);
}

.admin-panel__content-wrapper {
  flex: 1 0 auto;
  padding-bottom: 64px;
  background-color: var(--white);
}
.admin-panel__content {
  width: var(--main-content-width);
  max-width: var(--main-content-max-width);
  margin: 0 auto 24px auto;
}
.admin-panel__content-header {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px 0;
  border-bottom: 1px solid var(--dark-grey-d-3);
  font-weight: 500;
}
.admin-panel__content-empty {
  padding: 40px 16px;
  font-size: 18px;
  text-align: center;
}

@media (max-width: 1023px) {
  .admin-panel__content-wrapper {
    padding-bottom: 100px;
  }
  .admin-panel__content-header {
    display: none;
  }
  .admin-panel__pagination {
    padding-bottom: 0;
  }
}

@media (max-width: 767px) {
  .admin-panel__head-top {
    display: block;
  }
  .admin-panel__filter {
    display: block;
  }
  .admin-panel__filter-left + .admin-panel__filter-right {
    margin-top: 16px;
    padding-top: 16px;
    padding-left: 0;
    border-left: none;
    border-top: 1px solid var(--dark-grey-d-3);
  }
}
</style>
